
/* eslint-disable */
import CloudFun, { defineComponent, ref } from '@cloudfun/core'
import VXETable from 'vxe-table'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default defineComponent({
  components: {
    loading: VueLoading,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    const isLoading = ref(false);
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;
    const checkPermissions = (key: string) => {
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canImport = checkPermissions("Administrator");

    const importData = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        //VXETable.modal.alert(`${file.name}`);
        var pathName = type == 1 ? 'importData/mapping1' : 'importData/mapping2'
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        await model?.dispatch(pathName, { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: "完成匯入",
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const importUnitConversion = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        await model?.dispatch('importData/unitConversion', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: "完成匯入",
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const importEstimatedShipment = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        formData.append("type", type);
        isLoading.value = true;
        var count = await model?.dispatch('importData/estimatedShipment', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入(${count}筆資料)`,
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }
    
    const importProductNumberPurchaseRecord = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        var count = await model?.dispatch('importData/productNumberPurchaseRecord', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入(${count}筆資料)`,
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const importManufacturerEstimatedPurchase = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        var count = await model?.dispatch('importData/manufacturerEstimatedPurchase', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入(${count}筆資料)`,
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const importJobTicke = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        var count = await model?.dispatch('importData/manufacturerEstimatedPurchase', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入(${count}筆資料)`,
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const importInvoice = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        var count = await model?.dispatch('importData/manufacturerEstimatedPurchase', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入(${count}筆資料)`,
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const importInvoiceItem = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        var count = await model?.dispatch('importData/manufacturerEstimatedPurchase', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入(${count}筆資料)`,
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }
    const importJobTickeDelivery = async (type: any) => {
      try {
        const { file } = await VXETable.readFile({ types: ['xlsx', 'xls'] });
        const formData = new FormData();
        formData.append("files", file);
        isLoading.value = true;
        var count = await model?.dispatch('importData/importJobTickeDelivery', { formData });
        CloudFun.send("info", {
          subject: "執行成功",
          content: `完成匯入(${count}筆資料)`,
        });
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    return {
      grid,
      model,
      importData,
      importUnitConversion,
      importEstimatedShipment,
      importProductNumberPurchaseRecord,
      importManufacturerEstimatedPurchase,
      importInvoiceItem,
      importInvoice,
      importJobTicke,
      canImport,
      isLoading,
      importJobTickeDelivery,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
